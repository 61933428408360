import React, { useEffect, useState } from 'react';

const DashboardPage = () => {
    const [totalJournalists, setTotalJournalists] = useState(0);
    const [journalistsByCountry, setJournalistsByCountry] = useState({});

    useEffect(() => {
        const fetchDashboardData = async () => {
            try {
                // const response = await fetch('http://localhost:5001/dashboard-data');
                const response = await fetch('/api/dashboard-data');
                const data = await response.json();

                if (response.ok) {
                    setTotalJournalists(data.totalJournalists);
                    setJournalistsByCountry(data.journalistsByCountry);
                } else {
                    console.error('Failed to fetch dashboard data:', data.message);
                }
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchDashboardData();
    }, []);

    return (
        <div>
            <h2>Dashboard</h2>
            <p>Welcome to the dashboard! This will be your main admin panel.</p>
            <h3>Total Journalists: {totalJournalists}</h3>
            <h4>Journalists by Country:</h4>
            <ul>
                {Object.entries(journalistsByCountry).map(([country, count]) => (
                    <li key={country}>{country}: {count}</li>
                ))}
            </ul>
        </div>
    );
};

export default DashboardPage;
