import React from 'react';
import { Link } from 'react-router-dom';

const Navbar = ({ onLogout }) => {
    return (
        <nav>
            <ul>
                <li>
                    <Link to="/dashboard">Dashboard</Link>
                </li>
                <li>
                    <Link to="/ajouter-journaliste">Ajouter Journaliste</Link>
                </li>
                <li>
                    <Link to="/journalistes">Journalistes</Link>
                </li>
                <li>
                    <Link to="/settings">Réglages</Link>
                </li>
                <li>
                    <button className="logout-button" onClick={onLogout}>Logout</button>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
