import React, { useEffect, useState } from 'react';

const Journalistes = () => {
    const [journalists, setJournalists] = useState([]);

    useEffect(() => {
        const fetchJournalists = async () => {
            try {
                // const response = await fetch('http://localhost:5001/journalists');
                const response = await fetch('/api/journalists');
                const data = await response.json();
                setJournalists(data);
            } catch (error) {
                console.error('Error fetching journalists:', error);
            }
        };

        fetchJournalists();
    }, []);

    return (
        <div>
            <h2>Journalistes</h2>
            <table>
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Email</th>
                        <th>Location</th>
                        <th>LinkedIn</th>
                    </tr>
                </thead>
                <tbody>
                    {journalists.map((journalist) => (
                        <tr key={journalist.id}>
                            <td>{journalist.first_name}</td>
                            <td>{journalist.last_name}</td>
                            <td>{journalist.email}</td>
                            <td>{journalist.location}</td>
                            <td>
                                <a href={journalist.linkedin_url} target="_blank" rel="noopener noreferrer">
                                    View Profile
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Journalistes;
