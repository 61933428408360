import React, { useState } from 'react';

const SettingsPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setIsLoading(true);

        // try {
        //     // Send the email and password to the backend
        //     const response = await fetch('http://localhost:5001/save-linkedin-credentials', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ email, password }),
        //     });

        try {
            // Send the email and password to the backend
            const response = await fetch('/api/save-linkedin-credentials', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            if (data.success) {
                setMessage('Credentials saved successfully!');
            } else {
                setMessage('Failed to save credentials: ' + data.message);
            }
        } catch (error) {
            setMessage('An error occurred: ' + error.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            <h2>LinkedIn Credentials</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Saving...' : 'Save Credentials'}
                </button>
            </form>
            {message && <p>{message}</p>}
        </div>
    );
};

export default SettingsPage;
