import React, { useState } from 'react';

const AjouterJournaliste = () => {
    const [email, setEmail] = useState('');
    const [linkedinUrl, setLinkedinUrl] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Spinner state
    const [journalistData, setJournalistData] = useState(null); // State to store the journalist's data

    const trimLinkedinUrl = (url) => {
        const parts = url.split('/');
        if (parts.length > 5) {
            return parts.slice(0, 5).join('/');
        }

        return url;
    };

    const handleEmailChange = (e) => {
        const input = e.target.value;
        // Remove all spaces from the email input
        const trimmedEmail = input.replace(/\s+/g, '');
        setEmail(trimmedEmail);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setJournalistData(null); // Clear the displayed journalist data
        setIsLoading(true); // Show the spinner

        const trimmedUrl = trimLinkedinUrl(linkedinUrl);
        console.log(trimmedUrl);

        // try {
        //     const response = await fetch('http://localhost:5001/add-journalist', {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //         },
        //         body: JSON.stringify({ email, linkedin_url: trimmedUrl }),
        //     });
        try {
            const response = await fetch('/api/add-journalist', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, linkedin_url: trimmedUrl }),
            });

            const data = await response.json();

            if (data.success) {
                setMessage('Journalist added successfully!');
                setEmail('');
                setLinkedinUrl('');

                // Fetch the journalist's data from the backend
                // const journalistResponse = await fetch(`http://localhost:5001/journalists/${data.docId}`);
                const journalistResponse = await fetch(`/api/journalists/${data.docId}`);
                const journalistData = await journalistResponse.json();

                setJournalistData(journalistData); // Set the journalist's data to display it
            } else {
                setMessage('Failed to add journalist: ' + data.message);
            }
        } catch (error) {
            setMessage('An error occurred: ' + error.message);
        } finally {
            setIsLoading(false); // Hide the spinner
        }
    };

    return (
        <div>
            <h2>Ajouter Journaliste</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>Email:</label>
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        required
                    />
                </div>
                <div>
                    <label>LinkedIn URL:</label>
                    <input
                        type="url"
                        value={linkedinUrl}
                        onChange={(e) => setLinkedinUrl(e.target.value)}
                        required
                    />
                </div>
                <button
                    type="submit"
                    disabled={isLoading}
                    className={isLoading ? 'loading-button' : ''}
                >
                    {isLoading ? 'Recherche d\'information' : 'Ajouter Journaliste'}
                </button>
            </form>
            {message && <p>{message}</p>}
            {journalistData && (
                <div>
                    <h3>Journalist Information</h3>
                    <p><strong>Prenom:</strong> {journalistData.first_name}</p>
                    <p><strong>Nom:</strong> {journalistData.last_name}</p>
                    <p><strong>Pays:</strong> {journalistData.country}</p>
                    <p><strong>Ville:</strong> {journalistData.city}</p>
                    <p><strong>Sujets:</strong> {journalistData.topics.join(', ')}</p>
                </div>
            )}
        </div>
    );
};

export default AjouterJournaliste;
